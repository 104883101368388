import React from "react";
import PropertyAddHero from "../Component/PropertyAddHero";
import Layout from "../Component/Layout/Layout";

function Addproperty() {
  return (
    <Layout>
      <PropertyAddHero />
    </Layout>
  );
}

export default Addproperty;
