/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderCard from "../Loader/LoaderCard";

function Myproperties() {
  const [data, setData] = useState([]);
  const GetData = () => {
    axiosInstance
      .get(`/car`)
      .then((res) => {
        if (res?.data?.status === true) {
          const Data = res?.data?.data;
          const filter = Data?.filter((e) => e?.carstype === "sale");
          setData(filter);
          // setData(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handledelete = async (id) => {
    await axiosInstance
      .get(`/car-status-change/${id}`)
      .then((res) => {
        // console.log(res,'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {data?.length === 0 && (
        <div className="property-item col-mdd-6 flex-wrap">
          <LoaderCard lenght={2} />
        </div>
      )}
      <div className="newnew">
        {data?.map((e, i) => {
          return (
            <div className="property-item col-mdd-6">
              <div className="property--img">
                <a href="#">
                  <img
                    src={e?.car_images?.[0]?.image}
                    alt="property image"
                    className="img-responsive"
                  />
                  <span
                    className={
                      e?.carstype === "sale"
                        ? "property--status"
                        : "property--statusred"
                    }
                  >
                    For Sale
                  </span>
                </a>
              </div>
              <div className="property--content">
                <div className="property--info">
                  <h5 className="property--title">
                    <a href="#">{`${e?.year} ${e?.make?.name} ${e?.model?.name} ${e?.trim}`}</a>
                  </h5>
                  <p className="property--location">
                    {" "}
                    <i className="fa fa-map-marker" />
                    {/* {e?.location} */}
                    {`${e?.city},${e?.district},${e?.region}`}
                  </p>
                  <div className="flex11">
                    <p className="property--price">GH₵ {e?.price} </p>
                    <div style={{ display: "flex11" }}>
                      <button
                        className="bg-black p-xs border-none button-detail"
                        onClick={() =>
                          navigate(`/en-gh/edit_car_for_sale/${e?.id}`, {
                            state: e,
                          })
                        }
                      >
                        <i className="fa fa-edit" style={{ color: "white" }} />
                      </button>
                      <button
                        className="bg-black p-xs ml-xs border-none button-detail"
                        onClick={() => handledelete(e?.id)}
                      >
                        {e?.is_active === 0 ? (
                          <i class="fa fa-lock"></i>
                        ) : (
                          <i class="fa fa-unlock"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="property--features">
                  {/* {/* <p>{e?.description.slice(0, 83)}</p> */}
                  <div className="">
                    <img
                      className="mtt-10"
                      src="/assets/images/mileagelogo.svg"
                      alt=""
                    />
                    <span className="ml-nine bold font-20 text-black ">
                      {e?.mileage_type + " " + e?.mileage}
                    </span>
                  </div>
                  <div className="flex1">
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/images/transmissionlogo.svg"
                        alt=""
                      />
                      <span className="ml-nine bold font-20 text-black ">
                        {e?.transmission}
                      </span>
                    </div>
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/images/enginelogo.svg"
                        alt=""
                      />
                      <span className="ml-nine bold font-20 text-black ">
                        {e?.engine_size?.name}
                      </span>
                    </div>
                    {/* <div className="">
                               
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-fuel-type_670bab1e0b56aac2d6635a5cb353099061396d70dca9c904ed4d5cf16c704c58.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.fuel_type}
                                  </span>
                                </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Myproperties;
