/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { axiosInstance } from "../Config/AxiosInstance";
import { toast } from "react-toastify";
import { axiosData } from "../Config/Axios";
// import { axiosInstance } from "../Config/AxiosInstance";
import { GetData, Getlisting } from "./Aminittes";
import Select from "react-select";
import { Slider } from "@mui/material";
// Import Swiper React components
import HeroSlider from "./HeroSlider";
// import { ProtectedRoute } from "../Config/Notlogin";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { AuthContext } from "../Config/Context/Context";
import { GetMake, Getmodel } from "../Helper/PropertyService";

// import './styles.css';

// import required modules

// import required modules
// import { Pagination } from "swiper/modules";
const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black", // Replace with your desired color
  }),
  control: (provided) => ({
    ...provided,
    // Additional styles for the control if needed
  }),
};
const selectStylesone = {
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black", // Replace with your desired color
  }),
  control: (provided) => ({
    ...provided,
    // Additional styles for the control if needed
  }),
};
function Hero() {
  // const token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  // console.log(states);
  const [cities, setCities] = useState([]);
  // const [, setDistricts] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [citydata, setcitydata] = useState("");
  // const [selectedDistirct] = useState(null);
  // console.log(selectedCountry?.value);
  const [loading, setLoading] = useState(false);
  // const [, setError] = useState(null);
  // const [, settype] = useState([]);
  // const geonamesUsername = "sywyj";
  const [, setfeature] = useState([]);
  const [listing, setlisting] = useState([]);
  useEffect(() => {
    GetData().then((e) => {
      setfeature(e?.data?.data);
    });
    Getlisting().then((e) => {
      const filter = e?.data?.data?.filter((e) => e?.city);
      setlisting(filter);
    });
    // GetDataone().then((e) => {
    //   settype(e?.data?.data);
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const countryOptions = [
  //   {
  //     value: 2300660,
  //     label: 'Ghana',
  //   },
  // ];

  useEffect(() => {
    GetMake(setLoading, setStates);
  }, []);
  useEffect(() => {
    if (selectedState) {
    

      Getmodel(selectedState?.value, setLoading, setCities);
    } 
  }, [selectedState]);

// console.log(selectedCity)
// console.log(selectedState)
  const [open, setOpen] = useState(false);
  const [searching, setSearching] = useState([]);
  // const [selectedValues] = useState({});
  // console.log(selectedValues);
  const [value, setValue] = React.useState([10000, 800000]);
  // console.log(value, "value");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleSelectChange = (e) => {
  //   setSelectedValues({
  //     ...selectedValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // Fetch data based on selected values

  const toggleOptions = () => {
    setOpen(!open);
  };
  // const token = localStorage.getItem("Token");
  let { setFilters } = useContext(AuthContext);
  // console.log(setFilters)
  const Onsubmit = (event) => {
    event.preventDefault();
  
    const Data = new FormData();
    const params = new URLSearchParams();
  
    const updatedFilters = {
      selectedCity: [],
      selectedState: [],
      cityTown: citydata || "",
    };
  
    // Handle selected city
    if (selectedCity) {
      updatedFilters.selectedCity.push(selectedCity.value);
      Data.append("byModel", selectedCity.value);
      params.append("byModel", selectedCity.label);
    }
  
    // Handle selected state
    if (selectedState) {
      updatedFilters.selectedState.push(selectedState.value);
      Data.append("byMake", selectedState.value);
      params.append("byMake", selectedState.label);
    }
  
    // Handle city data
    if (citydata) {
      Data.append("byCity", citydata);
      params.append("byCity", citydata);
    }
  
    // Set filters
    setFilters(updatedFilters);
  
    // Uncomment these if needed
    Data.append("byMin", value[0]);
    Data.append("byMax", value[1]);
  
    axiosData
      .post("/car-search", Data)
      .then((response) => {
        // console.log("Form submitted successfully!", response);
        if (response.data) {
          const queryString = params.toString();
          const url = queryString ? `/en-gh/car?${queryString}` : "/en-gh/car";
  
          navigate(url, {
            state: response.data.cars,
          });
  
          toast.success(response.data.message);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // toast.error(error?.message);
        navigate("/en-gh/car", {
          state: [],
        });
      });
  };
  
  const handleCity = (e) => {
    const value = e.toLowerCase();
    setcitydata(value);
    const filtered = listing?.filter(
      (item) =>
        item.city.toLowerCase().includes(value) ||
        item.region.toLowerCase().includes(value) ||
        item.country.toLowerCase().includes(value)
    );
    setSearching(filtered);
    if (!value) {
      setSearching([]);
    }
    // console.log(filtered);
  };

  return (
    <section id="heroSearch" className="hero-search mtop-100 pt-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="slider--content">
              <div className="text-center">
                <h1>Connecting car dealers, buyers, and renters</h1>
              </div>
              <form className="mb-0">
                <div className="form-box search-properties">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <div className="form-group">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <Select
                            placeholder="Select Make"
                            options={states.map((state) => ({
                              label: state.name,
                              value: state.id,
                            }))}
                            styles={selectStyles}
                            onChange={setSelectedState}
                            isDisabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {selectedState && ( */}
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <div className="form-group">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <Select
                            placeholder="Select Model"
                            options={cities.map((model) => ({
                              label: model.name,
                              value: model.id,
                            }))}
                            styles={selectStylesone}
                            onChange={setSelectedCity}
                          />
                        </div>
                      </div>
                    </div>
                    {/* // )} */}
                    {/* {selectedCity && selectedState && ( */}
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <div className="form-group">
                        <div className="select--box">
                          <input
                            placeholder="Enter City/Town"
                            type="text"
                            value={citydata}
                            onChange={(e) => handleCity(e?.target?.value)}
                            className={`form-control`}
                            name="suburb"
                            // disabled={!selectedCity || loading}
                            id="suburb"
                          />
                          <ul
                            className={`${
                              searching.length === 0
                                ? "d-none"
                                : "dropdown-menu1"
                            }`}
                          >
                            {searching &&
                              Array.from(
                                new Set(
                                  searching?.map((item) =>
                                    item.city?.toLowerCase()
                                  )
                                )
                              ).map((item) => (
                                <li
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                  }}
                                  key={item.id}
                                  onClick={() => {
                                    setcitydata(item);
                                    setSearching([]);
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* )} */}

                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <button
                        className="btn btn--primary btn--block"
                        onClick={Onsubmit}
                      >
                        Search
                      </button>
                    </div>
                    {/* .col-md-3 end */}
                    {/* <div
                      className={`col-xs-12 col-sm-6 col-md-3  ${
                        open ? "option-open" : "option-hide"
                      }`}
                    >
                      <div className="form-group">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <select
                            name="type"
                            id="select-beds"
                            onChange={handleSelectChange}
                          >
                            <option>Any Type</option>
                            {type.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* .col-md-3 end */}
                    {/* <div
                      className={`col-xs-12 col-sm-6 col-md-3 ${
                        open ? "option-open" : "option-hide"
                      }`}
                    >
                      <div className="form-group ">
                        <div className="select--box">
                          <i className="fa fa-angle-down" />
                          <select
                            name="bath"
                            id="select-baths"
                            onChange={handleSelectChange}
                          >
                            <option>Features</option>
                            {feature.map((e) => {
                              return (
                                <option key={e.name} value={e.name}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div> */}
                    {/* .col-md-3 end */}
                    <div
                      className={`col-xs-12 col-sm-6 col-md-6 ${
                        open ? "option-open" : "option-hide"
                      }`}
                    >
                      <div className="filter mb-30">
                        <p>
                          <label htmlFor="amount">
                            Price Range: GH₵ {value[0]} - GH₵ {value[1]}{" "}
                          </label>

                          {/* <input
                            id="amount"
                            type="range"
                            className="amount mt--20"
                            value={priceRangeValue}
                            onChange={handlePriceRangeChange}
                          /> */}
                          <Slider
                            getAriaLabel={() => "Temperature range"}
                            value={value}
                            className="amount"
                            onChange={handleChange}
                            min={10000}
                            max={800000}
                            valueLabelDisplay="auto"
                            // getAriaValueText={valuetext}
                          />
                        </p>
                        <div className="slider-range" />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <a
                        href="#"
                        className={`less--options ${open ? "active" : ""}`}
                        onClick={toggleOptions}
                      >
                        {open ? "Less options" : "More options"}
                      </a>
                    </div>
                  </div>
                  {/* .row end */}
                </div>
                {/* .form-box end */}
              </form>
            </div>
          </div>
          {/* .container  end */}
        </div>
        {/* .slider-text end */}
      </div>
      <HeroSlider />
    </section>
  );
}

export default Hero;
