/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SocialSidebar from "../SocialSidebar";
import {useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import MyCarCom from "./MyCarCom";

function MyCarForRent() {

  // eslint-disable-next-line no-unused-vars
  const data = JSON.parse(localStorage.getItem("user"));
 

  const navigate = useNavigate();
 

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!token) {
      navigate("/");
    } else {
      navigate("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
<Layout>



      <section
        id="user-profile"
        className="user-profile"
        style={{ marginTop: "0px" }}
      >
        <div className="container">
          <div className="row">
            <SocialSidebar />

            <div className="col-xs-12 col-sm-12 col-md-8">
              <MyCarCom/>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    </div>
  );
}

export default MyCarForRent;
