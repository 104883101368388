import React, { useState, useEffect, useContext } from "react";
import "../Header.css";
// import axios from "axios";

// import { GetDataone } from "../Aminittes";
import ApartmentCom from "../Type/ApartmentCom";
import { AuthContext } from "../../Config/Context/Context";
import {
  dropdownOptions,
  Getdoors,
  GetengineSize,
  GetMake,
  Getmodel,
  GetTrim,
  Year,
  // year,
} from "../../Helper/PropertyService";

// const geonamesUsername = "sywyj";

const HeaderApartment = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [, setloader] = useState(true);
  const [makes, setmakes] = useState([]);
  let { filtersSale, setFiltersSale } = useContext(AuthContext);
  const [models, setModels] = useState([]);
  const [Engine, setengineSize] = useState([]);
  const [doors, setdoors] = useState([]);
  const [years, setyear] = useState([]);
  useEffect(() => {
    GetMake(setloader, setmakes);
    if (filtersSale?.selectedMake) {
      Getmodel(filtersSale?.selectedMake, setloader, setModels);
    }
    GetengineSize(setloader, setengineSize);
    Getdoors(setloader, setdoors);
    Year(setyear);
    if (filtersSale?.selectedvariant) {
      GetTrim(
        filtersSale?.selectedvariant,
        setloader,
        // settirm,
        filtersSale?.selectedMake,
        filtersSale?.selectedModel
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSale?.selectedModel, filtersSale?.selectedMake, filtersSale?.selectedtrim]);

  const [filteredData, setFilteredData] = useState([]);
  const [, setType] = useState([]);

  const toggleDropdownVisibility = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    setType([{ name: "Sedan" }, { name: "SUV" }, { name: "Truck" }]);
  }, []);

  const reset = () => {
    setFiltersSale({
      selectedMake: "",
      selectedModel: "",
      cityTown: "",
      years: "",
      transmissions: "",
      price: "",
      sorts: "",
      selectedvariant: "",
      fuelType: "",
      selectedtrim: "",
      engineSize: "",
      doors: "",
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFiltersSale((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const applyFilters = () => {
    setFilteredData(filtersSale);
  };

  const getFilterCount = () => {
    return Object.values(filtersSale).filter((value) => value).length;
  };

  useEffect(() => {
    const handleResize = () => {
      setDropdownVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="header1">
        <div className="flex button_filter">
          <button className="toggle-btnf" onClick={toggleDropdownVisibility}>
            <i
              className="fa fa-server"
              style={{ fontSize: "14px", marginRight: "10px" }}
            ></i>
            FiltersSale <span className="filter-count">{getFilterCount()}</span>
          </button>

          <button
            type="submit"
            className="btn btn--primary btn-filter2"
            onClick={reset}
          >
            Reset
          </button>
        </div>

        <div className={`${dropdownVisible ? "show dropdowns" : "d-none"}`}>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedMake"
              value={filtersSale.selectedMake}
              onChange={handleFilterChange}
            >
              <option value="">Make</option>
              {makes.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedModel"
              value={filtersSale.selectedModel}
              onChange={handleFilterChange}
            >
              <option value="">Model</option>
              {models.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <input
              type="text"
              placeholder="Trim"
              name="selectedtrim"
              className="filter-input"
              value={filtersSale.selectedtrim}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <input
              type="text"
              placeholder="CIty/Town"
              name="cityTown"
              className="filter-input"
              value={filtersSale.cityTown}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="years"
              value={filtersSale.years}
              onChange={handleFilterChange}
            >
              <option value="">Years</option>
              {years.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="transmissions"
              value={filtersSale.transmissions}
              onChange={handleFilterChange}
            >
              <option value="">Transmission</option>
              {dropdownOptions.transmissions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="price"
              value={filtersSale.price}
              onChange={handleFilterChange}
            >
              <option value="">Price</option>
              {dropdownOptions.price.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="sorts"
              value={filtersSale.sorts}
              onChange={handleFilterChange}
            >
              <option value="">Sort</option>
              {dropdownOptions.sorts.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="fuelType"
              value={filtersSale.fuelType}
              onChange={handleFilterChange}
            >
              <option value="">Fuel Type</option>
              {dropdownOptions.fuelType.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="engineSize"
              value={filtersSale.engineSize}
              onChange={handleFilterChange}
            >
              <option value="">Engine Size</option>
              {Engine?.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="doors"
              value={filtersSale.doors}
              onChange={handleFilterChange}
            >
              <option value="">No. of Doors</option>
              {doors.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="btn btn--primary btn-filter1"
            onClick={reset}
          >
            Reset
          </button>
        </div>
      </div>

      <ApartmentCom filters={filteredData} />
    </>
  );
};

export default HeaderApartment;
