import { toast } from "react-toastify";
import { axiosData } from "../Config/Axios";
import { axiosInstance } from "../Config/AxiosInstance";

export   const GetAllReview = (setloader,setData,id) => {
    setloader(true);
    axiosData
      .get(`/all-car-reviews?car_id=${id}`)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
          setloader(false);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setloader(true);
      });
  };
  export const HandleAddReview =(event,rating,comment,id,setRating,setComment)=>{
    const formData = new FormData();

    event.preventDefault();
    formData.append("star", rating);
    formData.append("comment", comment);
    formData.append("car_id", id);

    axiosInstance
      .post("/car-review", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(res?.data?.message);
          setRating(0);
          setComment("");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err?.response?.data?.message);
      });
  }