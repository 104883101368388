import React, { createContext, useState } from "react";
export const rootContext = createContext();
export default function RootContext({ children }) {
  const [make_id, setmake_id] = useState(1);
  const [model_id, setmodel_id] = useState(false);
  const [variant_id, setvariant_id] = useState(false);
  const [trim_id, settrim_id] = useState(false);

  return (
    <rootContext.Provider
      value={{
        make_id,
        model_id,
        trim_id,
        variant_id,
        setmodel_id,
        setmake_id,
        settrim_id,
        setvariant_id,
      }}
    >
      {children}
    </rootContext.Provider>
  );
}
