/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../Config/AxiosInstance";
import { toast } from "react-toastify";
import Signup from "./Signup";
import Forget from "./Forget";
import Reset from "./Reset";
// import { Link } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// const formatPhoneNumber = (value) => {
//   // Remove all non-digit characters
//   const digits = value.replace(/\D/g, '');

//   // Apply the formatting
//   if (digits.length <= 4) {
//     return digits;
//   } else if (digits.length <= 7) {
//     return `${digits.slice(0, 4)}-${digits.slice(4)}`;
//   } else {
//     return `${digits.slice(0, 4)}-${digits.slice(4, 7)}-${digits.slice(7, 10)}`;
//   }
// };
function Modal({ id, setcondition, condition }) {
  // const [state, setState] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    // email: Yup.string().email("Invalid email").required("Email is required"),
    // phone: Yup.string()
    //   .matches(/^\d{4}-\d{3}-\d{3}$/, "Phone number is not valid")
    //   .required("Phone Number is required"),
    phone: Yup.string()
      .matches(
        /^233\d{9}$/,
        "Phone number must start with 233 and be exactly 12 digits long"
      )
      .required("Phone number is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    terms: Yup.bool().oneOf([true], "You must accept the Terms of Service"),
    // role: Yup.string().required("Role is required"),
  });

  const initialValues = {
    // email: "",
    phone: "",
    password: "",
    role: "",
    terms: false,
  };

  const onSubmitLogin = (values, { setSubmitting }) => {
    const formData = new FormData();

    setSubmitting(true);
    // formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("password", values.password);
    formData.append("role", "user");

    axiosInstance
      .post("/login", formData)
      .then((res) => {
        if (res?.data?.data) {
          localStorage.setItem("Token", res?.data?.data?.accessToken);
          localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
          toast.success("Successfully Login");
          setcondition({
            login: false,
            signup: false,
            forget: false,
            reset: false,
          });
          window.location.reload();
          localStorage.removeItem("adSeen");
          setSubmitting(false);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.error("Error in login", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={`modal register-login-modal fade`}
      tabIndex={-1}
      role="dialog"
      id={id}
    >
      <div className={"modal-dialog modal-lg wi-400"} role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              {/* Nav tabs */}
              <ul className="nav nav-tabs">
                <li className={condition.login ? "active" : " "}>
                  <a
                    href="#login"
                    data-toggle="tab"
                    onClick={() =>
                      setcondition({
                        ...condition,
                        login: true,
                        signup: false,
                        forget: false,
                        reset: false,
                      })
                    }
                  >
                    Login
                  </a>
                </li>
                <li className={condition.signup ? "active" : ""}>
                  <a
                    href="#signup"
                    data-toggle="tab"
                    onClick={() =>
                      setcondition({
                        ...condition,
                        login: false,
                        signup: true,
                        forget: false,
                        reset: false,
                      })
                    }
                  >
                    Signup
                  </a>
                </li>
                <li className={condition.forget ? "active" : ""}>
                  <a
                    href="#forget"
                    data-toggle="tab"
                    onClick={() =>
                      setcondition({
                        ...condition,
                        login: false,
                        signup: false,
                        forget: true,
                        reset: false,
                      })
                    }
                  >
                    Forget
                  </a>
                </li>
                <li className={condition.reset ? "active" : " "}>
                  <a
                    href="#forget"
                    data-toggle="tab"
                    onClick={() =>
                      setcondition({
                        ...condition,
                        login: false,
                        signup: false,
                        forget: false,
                        reset: true,
                      })
                    }
                  >
                    Reset
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    condition.login ? "in active" : " "
                  }`}
                  id="login"
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitLogin}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form className="mb-0">
                        <div className="form-group">
                          <label htmlFor="Phone Number">Phone Number</label>
                          <Field
                            type="text"
                            id="phone"
                            className="form-control"
                            name="phone"
                            placeholder="Enter Number"
                            maxLength="12"
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ""); // Remove any non-digit characters
                              if (!value.startsWith("233")) {
                                // eslint-disable-next-line prefer-template
                                value = "233" + value.slice(3); // Ensure the value always starts with '233'
                              }
                              if (value.length > 12) {
                                value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                              }
                              setFieldValue("phone", value); // Update the value
                            }}
                            // value={field.value}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/*
                        <div className="or-text">
                          <span>or</span>
                        </div>
                       */}
                        {/* <div className="form-group">
                        <label>Phone Number*</label> */}
                        {/* <Field
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email Address"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          /> */}
                        {/* <PhoneInput
                            country={"gh"}
                            onlyCountries={['gh']}
                            name="phone"
                            value={state?.phone}
                            onChange={(phone) => setState({ phone })}
                          /> */}
                        {/* <Field
                            type="number"
                            className="form-control"
                            name="phone"
                            placeholder="Phone Number"
                          /> */}
                        {/* <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div> */}
                        <div className="form-group password-input-group">
                          <label>Password*</label>
                          <Field
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            name="password"
                            placeholder="Password"
                          />
                          <i
                            className={`fa ${
                              showPassword ? "fa-eye-slash" : "fa-eye"
                            } password-toggle-icon`}
                            onClick={togglePasswordVisibility}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="input-checkbox">
                          <label className="label-checkbox">
                            <span>
                              I have read and agree to the{" "}
                              <a href="https://www.company.rentisready.com/terms.php">
                                Terms of Service
                              </a>
                            </span>
                            <Field type="checkbox" name="terms" />
                            <span className="check-indicator" />
                          </label>
                          <ErrorMessage
                            name="terms"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        {/* <div className="form-group">
                          <Field
                            as="select"
                            className="form-control"
                            name="role"
                          >
                            <option value="">Select Role</option>
                            <option value="user">User</option>
                            <option value="host">Host</option>
                          </Field>
                          <ErrorMessage
                            name="role"
                            component="div"
                            className="text-danger"
                          />
                        </div> */}
                        <button
                          type="submit"
                          className="btn btn--primary btn--block"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Logging in..." : "Login"}
                        </button>
                        <button
                          type="button"
                          className="btn btn--primary btn--block"
                          onClick={() => {
                            setcondition({
                              login: false,
                              signup: false,
                              forget: false,
                              reset: false,
                            });
                            // Hide the modal using jQuery
                            window.$(`#${id}`).modal("hide");
                          }}
                        >
                          Cancel
                        </button>
                      </Form>
                    )}
                  </Formik>{" "}
                </div>

                <div
                  className={`tab-pane fade ${
                    condition.signup ? "in active" : " "
                  }`}
                  id="signup"
                >
                  <Signup
                    setcondition={setcondition}
                    condition={condition}
                    id={id}
                  />
                </div>
                <div
                  className={`tab-pane fade ${
                    condition.forget ? "in active" : " "
                  }`}
                  id="forget"
                >
                  <Forget
                    setcondition={setcondition}
                    condition={condition}
                    id={id}
                  />
                </div>
                <div
                  className={`tab-pane fade ${
                    condition.reset ? "in active" : " "
                  }`}
                  id="reset"
                >
                  <Reset
                    setcondition={setcondition}
                    condition={condition}
                    id={id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
