import React, { useState, useEffect, useContext } from "react";
import "./Header.css";
import Detail from "./Detail";
import { AuthContext } from "../Config/Context/Context";
import { dropdownOptions, Getdoors, GetengineSize, GetMake, Getmodel, GetTrim, Year } from "../Helper/PropertyService";

const HeaderHello = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [, setloader] = useState(true);
  const [makes, setmakes] = useState([]);
  let { filters, setFilters } = useContext(AuthContext);
  const [models, setModels] = useState([]);
  const [Engine, setengineSize] = useState([]);
  const [doors, setdoors] = useState([]);
  const [years, setyear] = useState([]);
  useEffect(() => {
    GetMake(setloader, setmakes);
    if (filters?.selectedMake) {
      Getmodel(filters?.selectedMake, setloader, setModels);
    }
    GetengineSize(setloader, setengineSize);
    Getdoors(setloader, setdoors);
    Year(setyear);
    if (filters?.selectedvariant) {
      GetTrim(
        filters?.selectedvariant,
        setloader,
        // settirm,
        filters?.selectedMake,
        filters?.selectedModel
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.selectedModel, filters?.selectedMake, filters?.selectedtrim]);

  const [filteredData, setFilteredData] = useState([]);
  const [, setType] = useState([]);

  const toggleDropdownVisibility = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    setType([{ name: "Sedan" }, { name: "SUV" }, { name: "Truck" }]);
  }, []);

  const reset = () => {
    setFilters({
      selectedMake: "",
      selectedModel: "",
      cityTown: "",
      years: "",
      transmissions: "",
      price: "",
      sorts: "",
      selectedvariant: "",
      fuelType: "",
      selectedtrim: "",
      engineSize: "",
      doors: "",
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const applyFilters = () => {
    setFilteredData(filters);
  };

  const getFilterCount = () => {
    return Object.values(filters).filter((value) => value).length;
  };

  useEffect(() => {
    const handleResize = () => {
      setDropdownVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="header1">
      <div className="flex button_filter">
          <button className="toggle-btnf" onClick={toggleDropdownVisibility}>
            <i
              className="fa fa-server"
              style={{ fontSize: "14px", marginRight: "10px" }}
            ></i>
            Filters <span className="filter-count">{getFilterCount()}</span>
          </button>

          <button
            type="submit"
            className="btn btn--primary btn-filter2"
            onClick={reset}
          >
            Reset
          </button>
        </div>

        <div className={`${dropdownVisible ? "show dropdowns" : "d-none"}`}>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedMake"
              value={filters.selectedMake}
              onChange={handleFilterChange}
            >
              <option value="">Make</option>
              {makes.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedModel"
              value={filters.selectedModel}
              onChange={handleFilterChange}
            >
              <option value="">Model</option>
              {models.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <input
              type="text"
              placeholder="Trim"
              name="selectedtrim"
              className="filter-input"
              value={filters.selectedtrim}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <input
              type="text"
              placeholder="CIty/Town"
              name="cityTown"
              className="filter-input"
              value={filters.cityTown}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="years"
              value={filters.years}
              onChange={handleFilterChange}
            >
              <option value="">Years</option>
              {years.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="transmissions"
              value={filters.transmissions}
              onChange={handleFilterChange}
            >
              <option value="">Transmission</option>
              {dropdownOptions.transmissions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="price"
              value={filters.price}
              onChange={handleFilterChange}
            >
              <option value="">Price</option>
              {dropdownOptions.price.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="sorts"
              value={filters.sorts}
              onChange={handleFilterChange}
            >
              <option value="">Sort</option>
              {dropdownOptions.sorts.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="fuelType"
              value={filters.fuelType}
              onChange={handleFilterChange}
            >
              <option value="">Fuel Type</option>
              {dropdownOptions.fuelType.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="engineSize"
              value={filters.engineSize}
              onChange={handleFilterChange}
            >
              <option value="">Engine Size</option>
              {Engine?.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="doors"
              value={filters.doors}
              onChange={handleFilterChange}
            >
              <option value="">No. of Doors</option>
              {doors.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="btn btn--primary btn-filter1"
            onClick={reset}
          >
            Reset
          </button>
        </div>
      </div>
      <Detail filters={filteredData} />
    </>
  );
};

export default HeaderHello;
