// import { toast } from "react-toastify";
import axios from "axios";
import { axiosInstance } from "../Config/AxiosInstance";

export const Add_Property = (
  values,
  setSubmitting,
  selectedCity,
  selectedState,
  selectedAmenities,
  images,
  setImageURLs,
  resetForm,
  setImages,
  setSelectedAmenities
) => {
  //   setSubmitting(true);
  //   console.log(images, "images Helper");
  // // Object.keys(values).forEach((key)=>{
  // //   console.log(` ${key}: ${values[key]}` )
  // // })
  //   const Data = new FormData();
  //   Data.append("make_id", values.make);
  //   Data.append("model_id", values.model);
  //   Data.append("year", values.year);
  //   Data.append("trim_id", values.trim);
  //   Data.append("body_type", values.body_type);
  //   Data.append("exterior", values.exterior);
  //   Data.append("interior", values.interior);
  //   Data.append("ensize", values.ensize);
  //   Data.append("noofdoor", values.noofdoor);
  //   Data.append("mileage", values.mileage);
  //   Data.append("mileage_type", values.mileage_type);
  //   Data.append("condition", values.condition);
  //   Data.append("variant_id", values.variants);
  //   Data.append("vin", values.vin);
  //   Data.append("fuel_tank", values.fuel_tank);
  //   Data.append("location", values.location);
  //   Data.append("carstype", values.carstype);
  //   Data.append("days_hours", values.days_hours);
  //   Data.append("pickup_location", values.pickup_location);
  //   Data.append("pickup_time", values.pickup_time);
  //   Data.append("dropoff_location", values.dropoff_location);
  //   Data.append("dropoff_time", values.dropoff_time);
  //   Data.append("price_per_day", values.price_per_day || 200);
  //   Data.append("negotiable", values.negotiable);
  //   Data.append("price", values.price);
  //   Data.append("city", 'Karachi');
  //   Data.append("description", values.description);
  //   Data.append("district", 'Accra');
  //   Data.append("country", "Ghana");
  //   Data.append("region", 'Greater Accra');
  //   // Data.append("address", values.address);
  //   Data.append("amenities", JSON.stringify(selectedAmenities));
  //   if (images.length >= 5 && images.length <= 20) {
  //     images.forEach((image, i) => {
  //       Data.append(`images[${i}]`, image);
  //     });
  //   } else {
  //     toast.error("Number of images is not between 5 and 20");
  //   }
  //   axiosInstance
  //     .post("/car", Data)
  //     .then((response) => {
  //       // console.log("Form submitted successfully!", response.data);
  //       toast.success("Car added successfully!");
  //       resetForm();
  //       setSelectedAmenities([]);
  //       setImages([]);
  //       setImageURLs([]);
  //     })
  //     .catch((error) => {
  //       console.error("Error submitting form:", error);
  //       // toast.error(error?.message);
  //     })
  //     .finally(() => {
  //       setSubmitting(false);
  //     });
};

export const GetMake = (setloader, setmake) => {
  setloader(true);
  axiosInstance
    .get(`/make`)
    .then((res) => {
      if (res?.data?.status === true) {
        setmake(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const GetengineSize = (setloader, setengineSize) => {
  setloader(true);
  axiosInstance
    .get(`/engine-size`)
    .then((res) => {
      if (res?.data?.status === true) {
        setengineSize(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getdoors = (setloader, setdoors) => {
  setloader(true);
  axiosInstance
    .get(`/door`)
    .then((res) => {
      if (res?.data?.status === true) {
        setdoors(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getbodytype = (setloader, setbodytype) => {
  setloader(true);
  axiosInstance
    .get(`/body-type`)
    .then((res) => {
      if (res?.data?.status === true) {
        setbodytype(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getinterior = (setloader, setinterior) => {
  setloader(true);
  axiosInstance
    .get(`/interior`)
    .then((res) => {
      if (res?.data?.status === true) {
        setinterior(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getcondition = (setloader, setcondition) => {
  setloader(true);
  axiosInstance
    .get(`/condition`)
    .then((res) => {
      if (res?.data?.status === true) {
        setcondition(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getexterior = (setloader, setexterior) => {
  setloader(true);
  axiosInstance
    .get(`/exterior`)
    .then((res) => {
      if (res?.data?.status === true) {
        setexterior(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getmodel = (make_id, setloader, setmodel) => {
  setloader(true);
  axiosInstance
    .get(`/model?make_id=${make_id}`)
    .then((res) => {
      if (res?.data?.status === true) {
        setmodel(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const Getvariant = (selectedModelId, setloader, setvariant, make_id) => {
  setloader(true);
  axiosInstance
    .get(`/variant?model_id=${selectedModelId}&make_id=${make_id}`)
    .then((res) => {
      if (res?.data?.status === true) {
        setvariant(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const GetTrim = (
  selectedvariantId,
  setloader,
  settirm,
  make_id,
  model_id
) => {
  setloader(true);
  axiosInstance
    .get(
      `/trim?variant_id=${selectedvariantId}&make_id=${make_id}&model_id=${model_id}`
    )
    .then((res) => {
      if (res?.data?.status === true) {
        settirm(res?.data?.data);
        setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
      setloader(true);
    });
};
export const years = [];
for (let i = 1980; i <= 2024; i++) {
  years.push({ label: i.toString(), value: i.toString() });
}

export const dropdownOptions = {
  years: [
    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2012", value: "2012" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    // Add more years as needed
  ],
  transmissions: [
    { label: "Automatic", value: "automatic" },
    { label: "Manual", value: "manual" },
    { label: "CVT", value: "CVT" },
    { label: "AMT", value: "AMT" },
  ],
  price: [
    { label: "Below GH₵ 60,000", value: "below_60000" },
    { label: "GH₵ 60,000 - 100,000", value: "60000_to_100000" },
    { label: "GH₵ 100,000 - 250,000", value: "100000_to_250000" },
    { label: "Above GH₵ 250,000", value: "above_250000" },
  ],
  price_per_day: [
    { label: "Below GH¢400", value: "400" },
    { label: "GH¢400 - GH¢600", value: "400_to_600" },
    { label: "GH¢600 - GH¢800", value: "600_to_800" },
    { label: "GH¢800 - GH¢1000", value: "800_to_1000" },
    { label: "Above GH₵ 1000", value: "above_1000" },
  ],
  sorts: [
    { label: "Newest-to-oldest", value: "desc" },
    { label: "Oldest-to-newest", value: "asc" },
    { label: "Price - highest-to-lowest", value: "price_desc" },
    { label: "Price - lowest-to-highest", value: "price_asc" },
  ],
  fuelType: [
    { label: "Petrol", value: "petrol" },
    { label: "Diesel", value: "diesel" },
  ],
  engineSize: [
    { label: "Below 1.0L", value: "below_1.0" },
    { label: "1.0L - 2.0L", value: "1.0_to_2.0" },
    { label: "Above 2.0L", value: "above_2.0" },
  ],
  doors: [
    { label: "2", value: "2" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "More than 5", value: "more_than_5" },
  ],
};
export const fetchStates = async (setLoading, setStates, geonamesUsername) => {
  setLoading(true);
  try {
    const response = await axios.get(
      `https://secure.geonames.org/childrenJSON?geonameId=${2300660}&username=${geonamesUsername}`
    );
    const stateOptions = response.data.geonames.map((state) => ({
      value: state.geonameId,
      label: state.name,
    }));
    setStates(stateOptions);
  } catch (error) {
    console.error("Error fetching states:", error);
  } finally {
    setLoading(false);
  }
};
export const geonamesUsername = "sywyj";
export const Year = (setyear) => {
  axiosInstance
    .get(`/year`)
    .then((res) => {
      if (res?.data?.status === true) {
        setyear(res?.data?.data);
        // setloader(false);
      }
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};