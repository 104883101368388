import { createContext, useState } from "react";

export const AuthContext = createContext();
export default function AuthProvider({ children }) {
  const [filters, setFilters] = useState({
    selectedMake: "",
    selectedModel: "",
    cityTown: "",
    years: "",
    transmissions: "",
    price: "",
    sorts: "",
    selectedtrim:'',
    fuelType: "",
    engineSize: "",
    doors: "",
    selectedvariant: "",
  });
  const [filtersRent, setFiltersRent] = useState({
    selectedMake: "",
    selectedModel: "",
    cityTown: "",
    years: "",
    transmissions: "",
    price_per_day: "",
    sorts: "",
    selectedtrim:'',
    fuelType: "",
    engineSize: "",
    doors: "",
    selectedvariant: "",
  });
  const [filtersSale, setFiltersSale] = useState({
    selectedMake: "",
    selectedModel: "",
    cityTown: "",
    years: "",
    transmissions: "",
    price: "",
    sorts: "",
    selectedtrim:'',
    fuelType: "",
    engineSize: "",
    doors: "",
    selectedvariant: "",
  });
  return (
    <AuthContext.Provider value={{ filters, setFilters,filtersRent,setFiltersRent,filtersSale,setFiltersSale }}>
      {children}
    </AuthContext.Provider>
  );
}
