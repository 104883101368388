import { toast } from "react-toastify";
import { axiosData } from "../Config/Axios";

export const HandleFav = (id,user,GetData) => {
    if (!user) {
      toast.error("YOU MUST BE VERIFIED TO USE THIS FEATURE. ");
      return;
    }

    const FormDat = new FormData();
    FormDat.append("car_id", id);
    axiosData
      .post(`/add-to-favourite`, FormDat)
      .then((res) => {
        if (res?.data?.message === "Unsave") {
          toast.success("Unfavorite");
          GetData();
        } else {
          toast.success("Favorite");
          GetData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };